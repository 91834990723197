<template>
    <div class="container-fluid">
   <div class="row">
      <div class="col-sm-12">
         <div class="card">
            <div class="card-header d-flex justify-content-between">
               <div class="header-title">
                  <tab-nav id="profile-pills-tab" :pills="true" role="tablist" extraclass="d-flex nav nav-pills mb-3 text-center profile-tab">
                     <tab-nav-items id="paymentsList" @clickTab="activeTab" :active="true" dataToggle="pill" ariaControls="list1" role="tab" :ariaSelected="true" title="Lista de pagamentos" />
                     <tab-nav-items id="paymentStatement" @clickTab="activeTab" dataToggle="pill"  role="tab" ariaControls="list2" :ariaSelected="false" title="Extrato de pagamentos"  />
                     <tab-nav-items id="pendingStatement" @clickTab="activeTab" dataToggle="pill" role="tab" ariaControls="list3" :ariaSelected="false" title="Extrato de pendentes" />
                     <tab-nav-items id="missingPayments" @clickTab="activeTab" dataToggle="pill" role="tab" ariaControls="list4" :ariaSelected="false" title="Pagamentos em falta" />
                  </tab-nav>
               </div>
            </div>
            <div class="card-body tab-content">
               <div class="table-responsive fade tab-pane active show" id="list1">
                  <div class="row justify-content-between">
                     <div class="col-12 col-sm-6">
                        <div id="user_list_datatable_info" class="dataTables_filter">
                           <b-input v-model="filter" placeholder="Pesquisar"></b-input>
                           <br>
                        </div>
                     </div>
                     <div class="col-12 col-sm-6">
                        <select class="form-control form-control" v-model="currentYear">
                           <option selected disabled>Year</option>
                           <option value="2020">2020</option>
                           <option value="2021">2021</option>
                           <option value="2022">2022</option>
                        </select>
                     </div>
                     <div class="col-12 py-2">
                        <div class="list-files d-flex">
                           <router-link class="btn bg-primary" :to="{name: 'app.payments-add'}">
                              Gerar documento
                           </router-link>
                        </div>
                     </div>
                  </div>
                  <b-table
                     :items="payments" 
                     :fields="paymentsFields"
                     :per-page="perPage"
                     :current-page="currentPage"
                     :filter="filter"
                     @filtered="onFiltered"
                  >
                     <template #cell(data) = "{ item }">
                        {{ item.Data ? item.Data : '-' }}
                     </template>
                     <template #cell(entidade) = "{ item }">
                        {{ item.Entidade ? item.Entidade : '-' }}
                     </template>
                     <template #cell(valor) = "{ item }">
                        {{ item.Valor ? item.Valor : '-' }}
                     </template>
                     <template #cell(iban) = "{ item }">
                        {{ item.IBAN ? item.IBAN : '-' }}
                     </template>
                     <template #cell(referência) = "{ item }">
                        {{ item.Referência ? item.Referência : '-' }}
                     </template>
                     <template #cell(estado) = "{ item }">
                        {{ item.Estado ? item.Estado : '-' }}
                     </template>
                     <template #cell(fatura) = "{ item }">
                        {{ item.Fatura ? item.Fatura : '-' }}
                     </template>
                     <template #cell(pagamento) = "{ item }">
                        {{ item.Pagamento ? item.Pagamento : '-' }}
                     </template>
                     <template #cell(actions)="{item}">
                        <div class="d-flex align-items-center list-user-action">
                           <router-link class="btn btn-sm bg-primary mr-1" :to="{name: 'app.payments-edit', params: {payment: item}}">
                              <i class="ri-pencil-line mr-0"></i>
                           </router-link>
                           <a 
                              class="btn btn-sm bg-primary" 
                              @click="deleteItem(item.id)" 
                              data-toggle="tooltip" 
                              data-placement="top" 
                              title=""
                              data-original-title="Delete"
                           >
                              <i class="ri-delete-bin-line mr-0"></i>
                           </a>
                        </div>                     
                     </template>
                  </b-table>                            
               </div>
               <div class="table-responsive tab-pane fade" id="list2">
                  <div class="row justify-content-between">
                     <div class="col-12 col-sm-6">
                        <div id="user_list_datatable_info" class="dataTables_filter">
                           <b-input v-model="filter" placeholder="Pesquisar"></b-input>
                           <br>
                        </div>
                     </div>
                     <div class="col-12 py-2">
                        <div class="list-files d-flex">
                           <a href="#" class="btn bg-primary" @click="syncDocs">
                              Sincronizar
                           </a>
                        </div>
                     </div>
                  </div>
                  <b-table
                     :items="paymentStatement" 
                     :fields="paymentStatementFields"
                     :per-page="perPage"
                     :current-page="currentPage"
                     :filter="filter"
                     @filtered="onFiltered"
                  >
                     <template #cell(data) = "{ item }">
                        {{ item.Data ? item.Data : '-' }}
                     </template>
                     <template #cell(cliente) = "{ item }">
                        {{ item.Cliente ? item.Cliente : '-' }}
                     </template>
                     <template #cell(Contribuinte) = "{ item }">
                        {{ item.Contribuinte ? item.Contribuinte : '-' }}
                     </template>
                     <template #cell(ValorSemIva) = "{ item }">
                        {{ item['Valor sem IVA'] ? item['Valor sem IVA'] : '-' }}
                     </template>
                  </b-table>                            
               </div>
               <div class="table-responsive tab-pane fade" id="list3">
                  <div class="row justify-content-between">
                     <div class="col-12 col-sm-6">
                        <div id="user_list_datatable_info" class="dataTables_filter">
                           <b-input v-model="filter" placeholder="Pesquisar"></b-input>
                           <br>
                        </div>
                     </div>
                     <div class="col-12 py-2">
                        <div class="list-files d-flex">
                           <a href="#" class="btn bg-primary" @click="syncDocs">
                              Sincronizar
                           </a>
                        </div>
                     </div>
                  </div>
                  <b-table
                     :items="pendingStatement" 
                     :fields="pendingStatementFields"
                     :per-page="perPage"
                     :current-page="currentPage"
                     :filter="filter"
                     @filtered="onFiltered"
                  >
                     <template #cell(data) = "{ item }">
                        {{ item.Data ? item.Data : '-' }}
                     </template>
                     <template #cell(cliente) = "{ item }">
                        {{ item.Cliente ? item.Cliente : '-' }}
                     </template>
                     <template #cell(estado) = "{ item }">
                        {{ item.Estado ? item.Estado : '-' }}
                     </template>
                     <template #cell(NaoVencido) = "{ item }">
                        {{ item['Não vencido'] ? item['Não vencido'] : '-' }}
                     </template>
                     <template #cell(TotalPago) = "{ item }">
                        {{ item['Total pago'] ? item['Total pago'] + '€' : '-' }}
                     </template>
                     <template #cell(SIVA) = "{ item }">
                        {{ item['S/IVA'] ? item['S/IVA'] + '€' : '-' }}
                     </template>
                     <template #cell(TotalPendente) = "{ item }">
                        {{ item['Total pendente'] ? item['Total pendente'] + '€' : '-' }}
                     </template>
                  </b-table>                            
               </div>
               <div class="table-responsive tab-pane fade" id="list4">
                  <div class="row justify-content-between">
                     <div class="col-12 col-sm-6">
                        <div id="user_list_datatable_info" class="dataTables_filter">
                           <b-input v-model="filter" placeholder="Pesquisar"></b-input>
                           <br>
                        </div>
                     </div>
                     <div class="col-12 py-2">
                        <div class="list-files d-flex">
                           <a href="#" class="btn bg-primary" @click="syncDocs">
                              Sincronizar
                           </a>
                        </div>
                     </div>
                  </div>
                  <b-table
                     :items="missingPayments" 
                     :fields="missingPaymentsFields"
                     :per-page="perPage"
                     :current-page="currentPage"
                     :filter="filter"
                     @filtered="onFiltered"
                  >
                     <template #cell(Contribuinte) = "{ item }">
                        {{ item.Contribuinte ? item.Contribuinte : '-' }}
                     </template>
                     <template #cell(NaoVencido) = "{ item }">
                        {{ item['Não vencido'] ? item['Não vencido'] : '-' }}
                     </template>
                     <template #cell(TotalPago) = "{ item }">
                        {{ item['Total pago'] ? item['Total pago'] + '€' : '-' }}
                     </template>
                     <template #cell(1-30-dias) = "{ item }">
                        {{ item['1 - 30 dias'] ? item['1 - 30 dias'] + '€' : '-' }}
                     </template>
                     <template #cell(31-60-dias) = "{ item }">
                        {{ item['31 - 60 dias'] ? item['31 - 60 dias'] + '€' : '-' }}
                     </template>
                     <template #cell(61-90-dias) = "{ item }">
                        {{ item['61 - 90 dias'] ? item['61 - 90 dias'] + '€' : '-' }}
                     </template>
                     <template #cell(+90-dias) = "{ item }">
                        {{ item['+ 90 dias'] ? item['+ 90 dias'] + '€' : '-' }}
                     </template>
                  </b-table>                            
               </div>
               <div class="row justify-content-between mt-3">
                  <div id="user-list-page-info" class="col-md-6">
                     <span>A apresentar o intervalo {{startIndex + 1}}-{{endIndex > totalRows ? totalRows : endIndex}} de {{totalRows}} registos</span>
                  </div>
                  <div class="col-md-6">
                     <nav aria-label="Page navigation example">
                        <ul class="pagination justify-content-end mb-0">
                            <bPagination 
                              :total-rows="totalRows" 
                              v-model="currentPage" 
                              :per-page="perPage" 
                              @input ="updatePage(currentPage)"
                            >
                            </bPagination>
                        </ul>
                     </nav>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
</template>
<script>
import { v4 as uuidv4 } from 'uuid';
export default {
    name:'UserList',
    data() {
        return {
            payments: localStorage.getItem('payments') && Boolean(localStorage.getItem('payments')) ? JSON.parse(localStorage.getItem('payments')) : [],
            paymentStatement: localStorage.getItem('paymentStatement') && Boolean(localStorage.getItem('paymentStatement')) ? JSON.parse(localStorage.getItem('paymentStatement')) : [],
            pendingStatement: localStorage.getItem('pendingStatement') && Boolean(localStorage.getItem('pendingStatement')) ? JSON.parse(localStorage.getItem('pendingStatement')) : [],
            missingPayments: localStorage.getItem('missingPayments') && Boolean(localStorage.getItem('missingPayments')) ? JSON.parse(localStorage.getItem('missingPayments')) : [],
            paymentsFields: ['Data', 'Entidade', 'Valor', 'IBAN', 'Referência', 'Estado', 'Fatura', 'Pagamento', {actions: { label: 'Ações'}}],
            paymentStatementFields: ['Data', 'Cliente', 'Contribuinte', {key: 'ValorSemIva', label: 'Valor sem IVA'}],
            pendingStatementFields: ['Cliente', 'Estado', 'Data',{key: 'NaoVencido', label: 'Não vencido'}, {key: 'SIVA', label: 'S/IVA'}, {key: 'TotalPago', label: 'Total pago'}, {key: 'TotalPendente', label: 'Total pendente'}],
            missingPaymentsFields: ['Cliente', 'Contribuinte',{key: 'NaoVencido', label: 'Não vencido'}, {key: '1-30-dias', label: '1 - 30 Dias'}, {key: '31-60-dias', label: '31 - 60 Dias'}, {key: '61-90-dias', label: '61 - 90 Dias'}, {key: '+90-dias', label: '+ 90 Dias'}],
            perPage: 5,
            currentPage : 1,
            startIndex : 0,
            endIndex : 5,
            totalRows: 1,
            filter: null,
            currentYear: "2022",
            active: false
        }
    },
    mounted () {
      if(!localStorage.getItem('payments') || !localStorage.getItem('paymentStatement') || !localStorage.getItem('pendingStatement') || !localStorage.getItem('missingPayments')) {
         this.readTextFile('https://wilddourocrm.painelgestao.pt/csv/pagamentos 2022.csv', this.payments);
         this.readTextFile('https://wilddourocrm.painelgestao.pt/csv/Extracto-de-Pagamentos.csv', this.paymentStatement);
         this.readTextFile('https://wilddourocrm.painelgestao.pt/csv/Extracto-de-Pendentes.csv', this.pendingStatement);
         this.readTextFile('https://wilddourocrm.painelgestao.pt/csv/Pagamentos-em-Falta.csv', this.missingPayments);
      }
      this.totalRowslists(this.payments.length);
   },
   methods: {
      totalRowslists: function(list) {
         this.totalRows = list;
      },
      syncDocs()
      {
         setTimeout(function(){alert('Documentos sincronizados com sucesso.')}, 1000);
      },
      activeTab(val) {
         switch (val) {
            case '#list1': 
               this.currentPage = 1;
               this.totalRowslists(this.payments.length);
            break;
            case '#list2': 
               this.currentPage = 1;
               this.totalRowslists(this.paymentStatement.length);
            break;
            case '#list3': 
               this.currentPage = 1;
               this.totalRowslists(this.pendingStatement.length);
            break;
            case '#list4': 
               this.currentPage = 1;
               this.totalRowslists(this.missingPayments.length);
            break;
            default:
            alert(`Sorry, list ${val} does not exist`);
         }
      },
      readTextFile(file, list) {
         let rawFile = new XMLHttpRequest();
         rawFile.open("GET", file, false);
         rawFile.onreadystatechange = function ()
         {
            if(rawFile.readyState === 4)
            {
               if(rawFile.status === 200 || rawFile.status == 0)
               {
                  let csv = rawFile.responseText;
                  let lines = csv.split('\n');

                  // Get structure of object
                  let headers = lines[0].split(";") 

                  for(let line = 1; line < lines.length; line++){
                        let values = lines[line].split(";");
                        let payment = {};

                        for (let index = 0; index < values.length - 1; index++) {
                           let key = headers[index];
                           payment[key] = values[index];
                        }
                        payment.id = uuidv4();
                        list.push(payment);
                  }        
               }
            }
         }
         rawFile.send(null);
      },
      updatePage : function(activePage) {
         this.currentPage = activePage;
         this.startIndex = (this.currentPage * 5) - 5;
         this.endIndex = this.startIndex + 5;
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
      deleteItem(id) {
         this.payments.splice(this.payments.findIndex(function(i){
            return i.id === id;
         }), 1);
         localStorage.setItem('payments', JSON.stringify(this.payments))
         this.totalRows = this.payments.length;
      }
    },
    watch: {
      currentYear(val) {
         if(val === "2020") {
            this.payments = [];
            this.currentPage = 1;
            this.readTextFile('https://wilddourocrm.painelgestao.pt/csv/pagamentos 2020.csv', this.payments);
            this.totalRowslists(this.payments.length);
         }
         else if(val === "2021") {
            this.payments = [];
            this.currentPage = 1;
            this.readTextFile('https://wilddourocrm.painelgestao.pt/csv/pagamentos 2021.csv', this.payments);
            this.totalRowslists(this.payments.length);
         }else{
            this.payments = [];
            this.currentPage = 1;
            this.readTextFile('https://wilddourocrm.painelgestao.pt/csv/pagamentos 2022.csv', this.payments);
            this.totalRowslists(this.payments.length);
         }
      },
      payments: function() {
         localStorage.setItem('payments', JSON.stringify(this.payments))
      },
      paymentStatement: function() {
         localStorage.setItem('paymentStatement', JSON.stringify(this.paymentStatement))
      },
      pendingStatement: function() {
         localStorage.setItem('pendingStatement', JSON.stringify(this.pendingStatement))
      },
      missingPayments: function() {
         localStorage.setItem('missingPayments', JSON.stringify(this.missingPayments))
      }
    }
}
</script>
<style scoped>
>>>.page-link:focus{
   box-shadow: none;
}
</style>